export function getYouTubeVideoIdFromUrl (url) {
  // Our regex pattern to look for a youTube ID
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  // Match the url with the regex
  const match = url.match(regExp)
  // Return the result
  return match && match[2].length === 11 ? match[2] : undefined
}

export function getVimeoIdFromUrl (url) {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url)
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1]
  }
}

export function getPandaPrefixFromUrl (url) {
  const regExp = /https:\/\/(.*).tv.pandavideo.com.br/i
  const match = url.match(regExp)
  if (match) {
    return match[1]
  }
}

export function getPandaVideoIdFromUrl (url) {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const regExp = /pandavideo.*v=([a-z0-9-]+)/i
  // Match the url with the regex
  const match = url.match(regExp)
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1]
  }
}

export function generateEmbedUrl (url) {
  const videoId = getVideoIdFromUrl(url)

  if (url.includes('youtu')) {
    return generateYouTubeUrl(videoId)
  } else if (url.includes('vimeo')) {
    return generateVimeoUrl(videoId)
  } else if (url.includes('pandavideo')) {
    const prefix = getPandaPrefixFromUrl(url)
    return generatePandaUrl(prefix, videoId)
  }
}

export function getVideoIdFromUrl (url) {
  if (url.includes('youtu')) {
    return getYouTubeVideoIdFromUrl(url)
  } else if (url.includes('vimeo')) {
    return getVimeoIdFromUrl(url)
  } else if (url.includes('pandavideo')) {
    return getPandaVideoIdFromUrl(url)
  }
}

export function generateYouTubeUrl (videoId) {
  return `https://www.youtube.com/embed/${videoId}?autoplay=0&disablekb=1&modestbranding=1&controls=1&mute=0&rel=0&loop=1&enablejsapi=1&widgetid=1&origin=${window.location.origin}`
}

export function generateVimeoUrl (videoId) {
  return `https://player.vimeo.com/video/${videoId}?autopause=0&autoplay=0&loop=0&title=0&byline=0&portrait=0&muted=0`
}

export function generatePandaUrl (prefix, videoId) {
  return `https://${prefix}.tv.pandavideo.com.br/embed/?v=${videoId}`
}
