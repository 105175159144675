import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'responsesApi' })

  /**
   * Returns all integrations of a given form.
   *
   * @param {String} formId
   * @returns {Promise<Array<{Object}>>} Returns an array of integrations.
   * @memberof IntegrationsService
   */
  async function find (formId) {
    return (await repository.find(`forms/${formId}/logs`)).data
  }

  return {
    find
  }
}
