import { removeTagsByAttribute } from '~/lib/utils/string.utils'

const actions = {
  async toggleUtmParameters ({ commit, state, dispatch }, enable) {
    if (enable) {
      commit('ENABLE_UTM_PARAMETERS')
    } else {
      dispatch('removeTrackingFromFields')
      dispatch('removeTrackingFromPrefill')
      commit('DISABLE_UTM_PARAMETERS')
    }

    const tracking = state.form.tracking
    await this.$api.forms.update(state.form.id, { tracking })
  },
  removeTrackingFromPrefill ({ state, commit }) {
    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

    for (const param of utmParameters) {
      const calendlyFieldsWithSpecificPrefill = state.fields.filter(field =>
        field.type === 'calendly' &&
          (field.settings.prefillNameField === `type='tracking' id='${param}'>${param}<` ||
           field.settings.prefillEmailField === `type='tracking' id='${param}'>${param}<`)
      )

      let formUpdated = false

      calendlyFieldsWithSpecificPrefill.forEach((field) => {
        const updatedSettings = { ...field.settings }
        if (field.settings.prefillNameField === `type='tracking' id='${param}'>${param}<`) {
          updatedSettings.prefillNameField = null
        }
        if (field.settings.prefillEmailField === `type='tracking' id='${param}'>${param}<`) {
          updatedSettings.prefillEmailField = null
        }

        this.$api.fields.update(field.id, { settings: updatedSettings })
        commit('UPDATE_FIELD_SETTINGS', { fieldId: field.id, settings: updatedSettings })

        formUpdated = true
      })

      if (formUpdated) {
        commit('UPDATE_FORM_IS_DIRTY', true)
      }
    }
  },
  async removeTrackingFromFields ({ state, commit, dispatch }) {
    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains any of the utmParameters
      for (const param of utmParameters) {
        const dataId = `data-id="${param}"`
        if (field.title && field.title.includes(dataId)) {
          const title = removeTagsByAttribute(field.title, 'data-id', param)
          this.$api.fields.update(field.id, { title })
          await dispatch('updateFieldTitle', { fieldId: field.id, title })
          // commit('UPDATE_FIELD_TITLE', { fieldId: field.id, title })
          formMustBeDirty = true
        }
      }

      // update description if it contains any of the utmParameters
      for (const param of utmParameters) {
        const dataId = `data-id="${param}"`
        if (field.description && field.description.includes(dataId)) {
          const description = removeTagsByAttribute(field.description, 'data-id', param)
          this.$api.fields.update(field.id, { description })
          commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
          formMustBeDirty = true
        }
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  }
}

const mutations = {
  ENABLE_UTM_PARAMETERS (state) {
    if (state.form.tracking === null) {
      state.form.tracking = []
    }
    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    state.form.tracking.push(...utmParameters)
  },

  DISABLE_UTM_PARAMETERS (state) {
    if (state.form.tracking === null) {
      state.form.tracking = []
    }
    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    state.form.tracking = state.form.tracking.filter(parameter => !utmParameters.includes(parameter))
  }
}

const getters = {
  isTrackingUsedOnContent: state => (tracking) => {
    const dataId = `data-id="${tracking}"`
    return state.fields.some(field => field.title?.includes(dataId) || field.description?.includes(dataId))
  },

  getTracking: (state) => {
    return state.form.tracking || []
  }
}

export default {
  actions,
  mutations,
  getters
}
