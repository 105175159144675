
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    variant: {
      type: String,
      default: 'green'
    }
  },

  computed: {
    color () {
      return `bg-${this.variant}`
    }
  }
}
