
export default {
  props: {
    plan: {
      type: String,
      default: ''
    },
    plans: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showPlans () {
      return !this.plans.includes(this.plan)
    },

    tooltip () {
      return this.plan && this.plans.length > 0
        ? `${this.$t('components.common.UpgradeBadge.available')} ${this.plans.join(', ')} ${this.$t('components.common.UpgradeBadge.pleaseUpgrade')}`
        : `${this.$t('components.common.UpgradeBadge.upgradeYourPlan')}`
    }
  }
}
