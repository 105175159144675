import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'answers' })

  /**
   * Returns all answers of a given field.
   *
   * @param {String} fieldId
   * @returns {Promise<Array<{Object}>>} Returns an array of answers.
   */
  async function findByField (fieldId, filters = {}) {
    return await repository.find(`fields/${fieldId}/answers`, filters)
  }

  /**
   * Returns all answers of a given response.
   *
   * @param {String} responseId
   * @returns {Promise<Array<{Object}>>} Returns an array of answers.
   */
  async function findByResponse (responseId) {
    return (await repository.find(`responses/${responseId}/answers`)).data
  }

  /**
   * Returns an answer with the given answerId.
   *
   * @param {String} answerId
   * @returns {Promise<{Object}>} Returns an answer.
   */
  async function findOne (answerId) {
    return await repository.findOne('answers', answerId)
  }

  /**
   * Creates a new answer.
   *
   * @param {String} responseId
   * @param {String} fieldId
   * @param {String} content
   * @returns {Promise<Object>} Returns the new answer created.
   */
  async function create (responseId, fieldId, content) {
    return await repository.create(`responses/${responseId}/fields/${fieldId}/answers`, { content })
  }

  /**
   * Creates or updates an answer.
   *
   * @param {String} responseId
   * @param {String} fieldId
   * @param {String} content
   * @returns {Promise<Object>} Returns the new answer created.
   */
  async function createOrUpdate (responseId, fieldId, content) {
    return await repository.update(`responses/${responseId}/fields/${fieldId}`, 'answers', { content })
  }

  /**
   * Updates an answer.
   *
   * @param {String} answerId
   * @param {String} content
   * @returns {Promise<Object>} Returns the updated answer.
   */
  async function update (answerId, content) {
    return await repository.update('answers', answerId, { content })
  }

  /**
   * Sets the deletedAt attribute of the given answer to the current date and time.
   *
   * @param {String} answerId
   * @returns {Promise<string>} Returns the id of the deleted answer.
   */
  async function del (answerId) {
    return await repository.delete('answers', answerId)
  }

  return {
    create,
    createOrUpdate,
    delete: del,
    findByField,
    findByResponse,
    findOne,
    update
  }
}
