import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'field' })

  /**
   * Returns all fields of a given form.
   *
   * @param {String} formId
   * @returns {Promise<Array<{Object}>>} Returns an array of fields.
   * @memberof FieldsService
   */
  async function find (formId, draft = true) {
    return (await repository.find(`forms/${formId}/fields`, { draft })).data
  }

  /**
   * Returns a field with the given fieldId.
   *
   * @param {String} fieldId
   * @returns {Promise<{Object}>} Returns a field.
   * @memberof FieldsService
   */
  async function findOne (fieldId) {
    return await repository.findOne('fields', fieldId)
  }

  /**
   * Creates a new field.
   *
   * @param {String} formId
   * @returns {Promise<Object>} Returns the new field created.
   * @memberof FieldsService
   */
  async function create (formId, title, type) {
    const payload = { title, type }

    payload.options = getDefaultOptions(type)

    if (type === 'welcome-screen' || type === 'ending-screen') {
      payload.position = 0
    }

    return await repository.create(`forms/${formId}/fields`, payload)
  }

  /**
   * Duplicates a field.
   *
   * @param {String} formId
   * @param {String} cloneFieldId
   * @returns {Promise<Object>} Returns the new field created.
   * @memberof FieldsService
   */
  async function duplicate (formId, cloneFieldId) {
    return await repository.create(`forms/${formId}/fields`, { cloneFieldId })
  }

  /**
   * Updates a field.
   *
   * @param {String} fieldId
   * @param {Object} field
   * @returns {Promise<Object>} Returns the new field created.
   * @memberof FieldsService
   */
  async function update (fieldId, field) {
    return await repository.update('fields', fieldId, field)
  }

  /**
   * Sets the deletedAt attribute of the given field to the current date and time.
   *
   * @param {String} fieldId
   * @returns {Promise<string>} Returns the id of the deleted field.
   * @memberof FieldsService
   */
  async function del (fieldId) {
    return await repository.delete('fields', fieldId)
  }

  function getDefaultOptions (type) {
    if (type) {
      return null
    }

    return null
  }

  return {
    create,
    delete: del,
    duplicate,
    find,
    findOne,
    update,
    getDefaultOptions
  }
}
