
import { isArrayEmpty } from '~/lib/utils/object.utils'

export const state = () => ({
  themes: []
})

export const getters = {
  getThemes (state) {
    return state.themes
  },
  getCurrentTheme (state, rootGetters) {
    return state.themes.find(theme => theme.id === rootGetters['forms/getCurrentThemeId'])
  },
  getCurrentThemeid (rootGetters) {
    return rootGetters['forms/getCurrentThemeId']
  }
}

export const mutations = {
  SET_THEMES (state, themes) {
    state.themes = themes
  },

  ADD_THEME (state, theme) {
    state.themes.push(theme)
  },

  UPDATE_THEME (state, theme) {
    const elementIndex = state.themes.findIndex(element => element.id === theme.id)
    state.themes.splice(elementIndex, 1, theme)
  },

  DELETE_THEME (state, themeId) {
    const elementIndex = state.themes.findIndex(element => element.id === themeId)
    state.themes.splice(elementIndex, 1)
  },

  RESET_STATE (state) {
    state.themes = []
  }
}

export const actions = {
  async retrieveThemes ({ commit, state }, workspaceId) {
    if (!isArrayEmpty(state.themes)) { return } // exits if the state was initialized already

    const themes = await this.$api.themes.find(workspaceId)
    commit('SET_THEMES', themes)
  },

  async updateTheme ({ commit }, { id, name, fontName, answerTextColor, buttonBackgroundColor, buttonTextColor, fieldTextColor, pageBackgroundColor }) {
    const theme = await this.$api.themes.update(id, { name, fontName, answerTextColor, buttonBackgroundColor, buttonTextColor, fieldTextColor, pageBackgroundColor })
    commit('UPDATE_THEME', theme)

    return theme
  },

  async addTheme ({ commit }, workspaceId) {
    const newTheme = await this.$api.themes.create(workspaceId, 'My theme', 'Arial', '#000000', '#7f7f7f', '#000000', '#ffffff', '#ffffff')
    commit('ADD_THEME', newTheme)

    return newTheme
  },

  async duplicateTheme ({ commit }, { workspaceId, cloneThemeId }) {
    const theme = await this.$api.themes.duplicate(workspaceId, cloneThemeId)
    commit('ADD_THEME', theme)

    return theme
  },

  async deleteTheme ({ commit }, themeId) {
    await this.$api.themes.delete(themeId)
    commit('DELETE_THEME', themeId)
  }
}
