const state = () => ({

})

const getters = {

}

const mutations = {

}

const actions = {
  // async onAuthStateChangedAction ({ dispatch }, { authUser }) {
  //   await dispatch('resetAllState')

  //   if (!authUser) {
  //     this.$router.push({ path: '/auth/signin' })
  //     return
  //   }

  //   // const { displayName, email, emailVerified, languageCode, phone, photoURL, uid } = authUser
  //   // commit('account/SET_USER', { displayName, email, emailVerified, languageCode, phone, photoURL, uid })

  //   await dispatch('account/retrieveWorkspaces')
  // },

  resetAllState ({ commit }) {
    commit('account/RESET_STATE')
    commit('forms/RESET_STATE')
    commit('themes/RESET_STATE')
  },

  resetWorkspaceRelatedState ({ commit }) {
    commit('forms/RESET_STATE')
    commit('themes/RESET_STATE')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
