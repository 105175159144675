import useIntegrationService from '~/lib/services/api/integrations.service'
import { isArrayEmpty } from '~/lib/utils/object.utils'

const actions = {
  async retrieveIntegrations ({ commit, state }, formId) {
    if (!isArrayEmpty(state.integrations)) {
      return
    }

    const integrationService = useIntegrationService({ $axios: this.$axios })
    const integrations = await integrationService.find(formId)

    commit('SET_INTEGRATIONS', integrations)
  },

  async addIntegration ({ commit, state }, integration) {
    const integrationService = useIntegrationService({ $axios: this.$axios })
    const newIntegration = await integrationService.create(state.form.id, integration.name, integration.type, integration.settings, integration.isActive, integration.credentialId)

    commit('ADD_INTEGRATION', newIntegration)
  },

  async updateIntegrationIsActive ({ commit }, { integrationId, isActive }) {
    const integrationService = useIntegrationService({ $axios: this.$axios })
    await integrationService.update(integrationId, { isActive })

    commit('UPDATE_INTEGRATION_ISACTIVE', { integrationId, isActive })
  },

  async updateIntegration ({ commit }, integration) {
    const integrationService = useIntegrationService({ $axios: this.$axios })
    await integrationService.update(integration.integrationId, integration)

    commit('UPDATE_INTEGRATION', integration)
  },

  async deleteIntegration ({ commit }, integrationId) {
    const integrationService = useIntegrationService({ $axios: this.$axios })
    await integrationService.delete(integrationId)

    commit('DELETE_INTEGRATION', integrationId)
  }
}

const mutations = {
  SET_INTEGRATIONS (state, integrations) {
    state.integrations = integrations
  },

  ADD_INTEGRATION (state, integration) {
    state.integrations.push(integration)
  },

  UPDATE_INTEGRATION_ISACTIVE (state, { integrationId, isActive }) {
    const elementIndex = state.integrations.findIndex(element => element.id === integrationId)
    const integration = state.integrations[elementIndex]
    integration.isActive = isActive
    state.integrations.splice(elementIndex, 1, integration)
  },

  UPDATE_INTEGRATION (state, { integrationId, name, settings, credentialId, isActive }) {
    const elementIndex = state.integrations.findIndex(element => element.id === integrationId)
    const integration = state.integrations[elementIndex]
    if (name) {
      integration.name = name
    }
    if (credentialId) {
      integration.credentialId = credentialId
    }
    if (settings) {
      integration.settings = settings
    }
    if (isActive !== undefined && isActive !== null) {
      integration.isActive = isActive
    }
    state.integrations.splice(elementIndex, 1, integration)
  },

  DELETE_INTEGRATION (state, integrationId) {
    const elementIndex = state.integrations.findIndex(element => element.id === integrationId)
    state.integrations.splice(elementIndex, 1)
  }
}

const getters = {
  getIntegrationsByType: state => (type) => {
    return state.integrations.filter(integration => integration.type === type)
  },

  getIntegrationByType: state => (type) => {
    return state.integrations.find(integration => integration.type === type) || null
  }
}

export default {
  actions,
  mutations,
  getters
}
