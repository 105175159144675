import { removeTagsByAttribute } from '~/lib/utils/string.utils'

const actions = {
  async addHiddenField ({ commit, state }, hiddenField) {
    commit('ADD_HIDDEN_FIELD', hiddenField)
    const hiddenFields = state.form.hiddenFields
    await this.$api.forms.update(state.form.id, { hiddenFields })
  },
  async removeHiddenFieldsFromFields ({ state, commit, dispatch }, varName) {
    const dataId = `data-id="${varName}"`
    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains the varName
      if (field.title && field.title.includes(dataId)) {
        const title = removeTagsByAttribute(field.title, 'data-id', varName)
        this.$api.fields.update(field.id, { title })
        await dispatch('updateFieldTitle', { fieldId: field.id, title })
        // commit('UPDATE_FIELD_TITLE', { fieldId: field.id, title })
        formMustBeDirty = true
      }

      // update description if it contains the varName
      if (field.description && field.description.includes(dataId)) {
        const description = removeTagsByAttribute(field.description, 'data-id', varName)
        this.$api.fields.update(field.id, { description })
        commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
        formMustBeDirty = true
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  },
  async deleteHiddenField ({ commit, state, dispatch }, { index, hiddenField }) {
    dispatch('removeHiddenFieldsFromFields', hiddenField)
    dispatch('removeCalendlyPrefill', { type: 'hiddenField', item: hiddenField })
    commit('DELETE_HIDDEN_FIELD', index)
    const hiddenFields = state.form.hiddenFields
    await this.$api.forms.update(state.form.id, { hiddenFields })
  },

  async updateHiddenField ({ commit, state }, { index, hiddenField }) {
    commit('UPDATE_HIDDEN_FIELD', { index, hiddenField })
    const hiddenFields = state.form.hiddenFields
    await this.$api.forms.update(state.form.id, { hiddenFields })
  },

  async updateHiddenFields ({ commit }, hiddenFields) {
    commit('UPDATE_HIDDEN_FIELDS', hiddenFields)
    await this.$api.forms.update(state.form.id, { hiddenFields })
  }
}

const mutations = {
  ADD_HIDDEN_FIELD (state, hiddenField) {
    if (state.form.hiddenFields === null) {
      state.form.hiddenFields = []
    }
    state.form.hiddenFields.push(hiddenField)
  },

  DELETE_HIDDEN_FIELD (state, index) {
    if (state.form.hiddenFields === null) {
      state.form.hiddenFields = []
    }
    state.form.hiddenFields.splice(index, 1)
  },

  UPDATE_HIDDEN_FIELD (state, { index, hiddenField }) {
    if (state.form.hiddenFields === null) {
      state.form.hiddenFields = []
    }
    state.form.hiddenFields.splice(index, 1, hiddenField)
  },

  UPDATE_HIDDEN_FIELDS (state, hiddenFields) {
    if (state.form.hiddenFields === null) {
      state.form.hiddenFields = []
    }
    state.form.hiddenFields = hiddenFields
  }
}

const getters = {
  isHiddenFieldUsedOnContent: state => (hiddenField) => {
    const dataId = `data-id="${hiddenField}"`

    return state.fields.some(field => field.title?.includes(dataId) || field.description?.includes(dataId))
  },

  getHiddenFields: (state) => {
    return state.form.hiddenFields || []
  }
}

export default {
  actions,
  mutations,
  getters
}
