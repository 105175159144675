
export default {
  layout: 'notauthenticated', // you can set a custom layout for the error page
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  }
}
