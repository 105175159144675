import ErrorNotification from '~/components/common/ErrorNotification.vue'
import UndoNotification from '~/components/common/UndoNotification.vue'
import SuccessNotification from '~/components/common/SuccessNotification.vue'

export class NotificationService {
  errors (vue, messages) {
    for (let i = 0; i < messages.length; i++) {
      this.error(vue, messages[i])
    }
  }

  error (vue, message) {
    vue.$oruga.notification.open({
      component: ErrorNotification,
      props: {
        message
      },
      position: 'bottom',
      variant: 'toast',
      indefinite: false,
      duration: 10000
    })
  }

  success (vue, message) {
    vue.$oruga.notification.open({
      component: SuccessNotification,
      props: {
        message
      },
      position: 'bottom',
      variant: 'toast',
      indefinite: false,
      duration: 5000
    })
  }

  undo (vue, message, onUndo) {
    vue.$oruga.notification.open({
      component: UndoNotification,
      props: {
        message,
        onUndo
      },
      position: 'bottom-left',
      variant: 'toast',
      indefinite: true
    })
  }
}
