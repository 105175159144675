import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'integration-schemas' })

  /**
   * Returns all integrations schemas.
   *
   * @returns {Promise<Array<{Object}>>} Returns an array of integrations.
   * @memberof IntegrationsService
   */
  async function find () {
    return (await repository.find('/integration-schemas')).data
  }

  return {
    find
  }
}
