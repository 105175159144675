const workspace = {
  computed: {
    workspaceUrl () {
      return `/workspaces/${this.$route.params.workspaceid}/forms`
    }
  },

  methods: {
    gotoWorkspace (workspaceId) {
      this.$router.push(`/workspaces/${workspaceId}/forms`)
    },

    gotoWorkspaceHome () {
      this.$router.push(`${this.workspaceUrl}`)
    },

    gotoHome () {
      this.$router.push('/')
    },

    gotoWorkspaceResource (underWorkspaceRoute) {
      this.$router.push(this.workspaceResourceUrl(underWorkspaceRoute))
    },

    workspaceResourceUrl (underWorkspaceRoute) {
      return `/workspaces/${this.$route.params.workspaceid}${underWorkspaceRoute}`
    },

    gotoFormsResource (underWorkspaceRoute) {
      this.$router.push(this.formsResourceUrl(underWorkspaceRoute))
    },

    formsResourceUrl (underWorkspaceRoute) {
      return `/forms${underWorkspaceRoute}`
    }
  }
}

export {
  workspace
}
