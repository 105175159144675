import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'integration' })

  /**
   * Returns all integrations of a given form.
   *
   * @param {String} formId
   * @returns {Promise<Array<{Object}>>} Returns an array of integrations.
   * @memberof IntegrationsService
   */
  async function find (formId) {
    return (await repository.find(`forms/${formId}/integrations`)).data
  }

  /**
   * Returns a integration with the given integrationId.
   *
   * @param {String} integrationId
   * @returns {Promise<{Object}>} Returns a integration.
   * @memberof IntegrationsService
   */
  async function findOne (integrationId) {
    return await repository.findOne('integrations', integrationId)
  }

  /**
   * Creates a new integration.
   *
   * @param {String} formId
   * @returns {Promise<Object>} Returns the new integration created.
   * @memberof IntegrationsService
   */
  async function create (formId, name, type, settings, isActive = false, credentialId = null) {
    const payload = { name, type, settings, isActive }

    if (credentialId) {
      payload.credentialId = credentialId
    }

    return await repository.create(`forms/${formId}/integrations`, payload)
  }

  /**
   * Updates a integration.
   *
   * @param {String} integrationId
   * @param {Object} integration
   * @returns {Promise<Object>} Returns the new integration created.
   * @memberof IntegrationsService
   */
  async function update (integrationId, integration) {
    return await repository.update('integrations', integrationId, integration)
  }

  /**
   * Sets the deletedAt attribute of the given integration to the current date and time.
   *
   * @param {String} integrationId
   * @returns {Promise<string>} Returns the id of the deleted integration.
   * @memberof IntegrationsService
   */
  async function del (integrationId) {
    return await repository.delete('integrations', integrationId)
  }

  return {
    create,
    delete: del,
    find,
    findOne,
    update
  }
}
