import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'medias' })

  /**
   * Returns all medias of a given workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<{Object}>>} Returns an array of medias.
   * @memberof MediasService
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/medias`)).data
  }

  /**
   * Returns a media with the given mediaId.
   *
   * @param {String} workspaceId
   * @param {String} mediaId
   * @returns {Promise<{Object}>} Returns a media.
   * @memberof MediasService
   */
  async function findOne (mediaId, draft = true) {
    return await repository.findOne('medias', mediaId, { draft })
  }

  /**
   * Creates a new media.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the new media created.
   * @memberof MediasService
   */
  async function create (workspaceId, formData) {
    return await repository.postData(`workspaces/${workspaceId}/medias`, formData)
  }

  /**
   * Duplicates a media.
   *
   * @param {String} workspaceId
   * @param {String} cloneMediaId
   * @returns {Promise<Object>} Returns the new media created.
   * @memberof MediasService
   */
  async function duplicate (workspaceId, cloneMediaId) {
    return await repository.create(`workspaces/${workspaceId}/medias`, { cloneMediaId })
  }

  /**
   * Updates a media.
   *
   * @param {String} mediaId
   * @param {Object} media
   * @returns {Promise<Object>} Returns the updated media.
   * @memberof MediasService
   */
  async function update (mediaId, media) {
    return await repository.update('medias', mediaId, media)
  }

  /**
   * Sets the deletedAt attribute of the given media to the current date and time.
   *
   * @param {String} mediaId
   * @returns {Promise<string>} Returns the id of the deleted media.
   * @memberof MediasService
   */
  async function del (mediaId) {
    return await repository.delete('medias', mediaId)
  }

  return {
    create,
    delete: del,
    duplicate,
    find,
    findOne,
    update
  }
}
