import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'workspace' })

  /**
   * Returns all workspaces of the authenticated user.
   *
   * @returns {Promise<Array<{Object}>>} Returns an array of forms.
   */
  async function find (filters) {
    return (await repository.find('workspaces', filters)).data
  }

  /**
 * Returns the workspace with the given workspaceId.
 *
 * @param {String} workspaceId
 * @returns {Promise<{Object}>} Returns a workspace.
 */
  async function findOne (workspaceId) {
    return await repository.findOne('workspaces', workspaceId)
  }

  /**
   * Creates a new workspace.
   *
   * @param {String} name
   * @returns {Promise<Object>} Returns the new workspace created.
   */
  async function create (name) {
    return await repository.create('workspaces', { name })
  }

  /**
   * Updates a workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the updated workpsace.
   */
  async function update (workspaceId, workspace) {
    return await repository.update('workspaces', workspaceId, workspace)
  }

  /**
   * Deletes the workspace with the given workspaceId.
   *
   * @param {String} workspaceId
   * @returns {Promise<string>} Returns the id of the deleted workspace.
   */
  async function del (workspaceId) {
    return await repository.delete('workspaces', workspaceId)
  }

  /**
   * Removes the user from the workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<string>} Returns the id of the deleted workspace.
   */
  async function deleteUser (workspaceId, userId) {
    return await repository.delete(`workspaces/${workspaceId}/users`, userId)
  }

  /**
   * Invite user to the workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the new user created.
   */
  async function invite (workspaceId, email) {
    return await repository.create(`workspaces/${workspaceId}/invitations`, { email })
  }

  return {
    create,
    delete: del,
    find,
    findOne,
    invite,
    update,
    deleteUser
  }
}
