import { normalizeError } from '../utils/error.utils'

export default {
  data () {
    return {
      formErrors: [],
      formSchema: null
    }
  },
  methods: {
    clearErrors () {
      this.formErrors = []
    },

    handleError (error) {
      const errorMessages = normalizeError(error)

      this.formErrors = errorMessages
      this.$notification.errors(this, errorMessages)
    },

    initModalSchemaFromFields (fields) {
      const formFields = fields.map((field) => {
        const { label, type, placeholder, validation } = field

        return { name: field.id, 'validation-name': label, label, type, placeholder, validation }
      })

      this.formSchema = formFields
    }
  }
}
