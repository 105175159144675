export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleOnComplete: function handleOnComplete(value) {
      this.context.model = value;
    },
    handleOnChange: function handleOnChange(value) {
      this.context.model = value;
    },
    handleClearInput: function handleClearInput(ref) {
      this.$refs[ref].clearInput();
    }
  }
};