import { getBrowserLocale, setUserLanguageCookie, getUserLanguageFromCookie } from '~/lib/utils/i18n.utils'

export default function ({ isHMR, store, app }) {
  if (isHMR) { // ignore if called from hot module replacement
    return
  }

  let userLanguage = getUserLanguageFromCookie()

  if (!userLanguage) {
    userLanguage = getBrowserLocale({ countryCodeOnly: true, fallbackCountryCode: 'en' })

    setUserLanguageCookie(userLanguage)
  }

  store.commit('account/SET_LANG', userLanguage)
  app.i18n.locale = store.state.account.locale

  // for server side only
  // if (req) {
  //   if (route.name) {
  //     let locale = null

  //     // check if the locale cookie is set
  //     if (req.headers.cookie) {
  //       const cookies = req.headers.cookie.split('; ').map(stringCookie => stringCookie.split('='))
  //       const cookie = cookies.find(cookie => cookie[0] === 'locale')

  //       if (cookie) {
  //         locale = cookie[1]
  //       }
  //     }

  //     // if the locale cookie is not set, fallback to accept-language header
  //     if (!locale) {
  //       locale = req.headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2)
  //     }

  //     store.commit('SET_LANG', locale)
  //     app.i18n.locale = store.state.account.locale
  //   }
  // }
}
