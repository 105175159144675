
import { mapState } from 'vuex'

export default {
  async fetch () {
    await this.$store.dispatch('account/retrieveBillingStatus')
  },

  computed: {
    ...mapState({
      subscription: state => state.account.subscription
    })
  },

  methods: {
    async gotoBillingPortal () {
      const { url } = await this.$api.billing.getPortal(window.location.href)
      window.location.href = url
    }
  }
}
