
export default {
  props: {
    message: {
      type: String,
      default () {
        this.$t('components.common.UndoNotification.default')
      }

    },
    onUndo: {
      type: Function,
      required: true,
      default: () => {}
    }
  },

  methods: {
    handleUndo () {
      this.onUndo()
      this.$emit('close')
    }
  }
}
