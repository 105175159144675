import cookie from 'cookie'
import useWorkspacesService from '~/lib/services/api/workspaces.service'
import useBillingService from '~/lib/services/api/billing.service'
import { isArrayEmpty } from '~/lib/utils/object.utils'
import { setUserLanguageCookie } from '~/lib/utils/i18n.utils'

export const state = () => ({
  workspaces: [],
  subscription: null,
  planFeatures: [],
  locales: [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'pt',
      name: 'Portuguese'
    },
    {
      code: 'es',
      name: 'Spanish'
    }
  ],
  locale: 'en'
})

export const getters = {
  getCurrentWorkspace (state, getters) {
    return state.workspaces.find(w => w.id === getters.currentWorkspaceId)
  },

  getCurrentWorkspaceName (state, getters) {
    const currentWorkspace = state.workspaces.find(w => w.id === getters.currentWorkspaceId)

    return currentWorkspace?.name || ''
  },

  getFirstWorkspaceId (state) {
    return state.workspaces[0]?.id || null
  },

  getWorkspaceById: state => (workspaceId) => {
    return state.workspaces.find(w => w.id === workspaceId)
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentWorkspaceId (state, getters, rootState) {
    return rootState.auth.user.currentWorkspaceId
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUser (state, getters, rootState) {
    return rootState.auth.user
  }
}

export const mutations = {
  SET_UNLIMITED_RESPONSES (state, value) {
    state.auth.user.additionalResponses = value
  },

  SET_WORKSPACES (state, workspaces) {
    state.workspaces = workspaces
  },

  UPDATE_WORKSPACE (state, workspace) {
    // update state of the workspace if it is the current one
    state.workspaces = [
      ...state.workspaces.filter(element => element.id !== workspace.id),
      workspace
    ]
  },

  DELETE_WORKSPACE (state, workspaceId) {
    const elementIndex = state.workspaces.findIndex(element => element.id === workspaceId)
    state.workspaces.splice(elementIndex, 1)
  },

  ADD_WORKSPACE (state, workspace) {
    state.workspaces.push(workspace)
  },

  SET_PLAN_FEATURES (state, planFeatures) {
    state.planFeatures = planFeatures
  },

  SET_SUBSCRIPTION (state, subscription) {
    state.subscription = subscription
  },

  RESET_STATE (state) {
    state.workspaces = []
    state.subscription = null
    state.planFeatures = []
    state.locale = 'en'
  },

  RESET_BILLING_STATUS (state) {
    state.subscription = null
    state.planFeatures = []
  },

  SET_LANG (state, locale) {
    if (state.locales.find(el => el.code === locale)) {
      state.locale = locale
    }
  }
}

export const actions = {
  async changeLocale ({ commit, rootState, state }, newLocale) {
    // set fallback locale if the new locale is not available, null or undefined
    if (!state.locales.find(el => el.code === newLocale)) {
      newLocale = 'en'
    }

    if (rootState.auth?.user?.language && rootState.auth?.user?.language !== newLocale) {
      await this.$api.users.update(rootState.auth.user.id, { language: newLocale })
    }

    setUserLanguageCookie(newLocale)
    commit('SET_LANG', newLocale)
  },

  async changeWorkspace ({ commit, dispatch, rootState }, workspaceId) {
    await this.$api.users.changeWorkspace(rootState.auth.user.id, workspaceId)
    dispatch('resetWorkspaceRelatedState', null, { root: true })
    commit('RESET_BILLING_STATUS')

    // commit('SET_CURRENT_WORKSPACE', workspaceId)
    // commit('auth/SET', { key: 'user/currentWorkspaceId', value: workspaceId }, { root: true })
    const user = { ...rootState.auth.user }
    user.currentWorkspaceId = workspaceId
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async retrieveWorkspaces ({ commit, state }) {
    if (!isArrayEmpty(state.workspaces)) { return }

    const workspacesService = useWorkspacesService({ $axios: this.$axios })
    const workspaces = await workspacesService.find()

    // commit('SET_CURRENT_WORKSPACE', rootState.auth.user.currentWorkspaceId)
    commit('SET_WORKSPACES', workspaces)
  },

  async retrieveBillingStatus ({ commit, state }) {
    if (state.subscription) { return }

    const billingService = useBillingService({ $axios: this.$axios })
    const { planFeatures, subscription } = await billingService.getStatus()

    commit('SET_PLAN_FEATURES', planFeatures)
    commit('SET_SUBSCRIPTION', subscription)
  },

  async renameWorkspace ({ commit }, { workspaceId, name }) {
    const workspacesService = useWorkspacesService({ $axios: this.$axios })
    const workspace = await workspacesService.update(workspaceId, { name })

    commit('UPDATE_WORKSPACE', workspace)
  },

  async changeSubdomain ({ commit }, { workspaceId, subdomain }) {
    const workspacesService = useWorkspacesService({ $axios: this.$axios })
    const workspace = await workspacesService.update(workspaceId, { subdomain })

    commit('UPDATE_WORKSPACE', workspace)
  },

  async deleteWorkspace ({ commit, state, getters }, workspaceId) {
    const workspacesService = useWorkspacesService({ $axios: this.$axios })
    await workspacesService.delete(workspaceId)

    commit('DELETE_WORKSPACE', workspaceId)
    // commit('SET_CURRENT_WORKSPACE', state.workspaces[0]?.id)
    const user = { ...getters.getUser }
    user.currentWorkspaceId = state.workspaces[0]?.id
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async createWorkspace ({ commit, dispatch }, workspaceName) {
    const workspacesService = useWorkspacesService({ $axios: this.$axios })
    const workspace = await workspacesService.create(workspaceName)

    commit('ADD_WORKSPACE', workspace)

    await dispatch('changeWorkspace', workspace.id)

    return workspace.id
  },

  async updateTracking ({ commit, rootState }) {
    const user = { ...rootState.auth.user }
    const cookieTracking = JSON.parse(cookie.parse(document.cookie).yfTracking ?? null)
    const tracking = {
      referredBy: cookie.parse(document.cookie)?.yfAffiliate ?? '',
      utmSource: cookieTracking?.utmSource ?? '',
      utmMedium: cookieTracking?.utmMedium ?? '',
      utmCampaign: cookieTracking?.utmCampaign ?? '',
      utmTerm: cookieTracking?.utmTerm ?? '',
      utmContent: cookieTracking?.utmContent ?? '',
      referrer: cookieTracking?.referrer ?? ''
    }

    await this.$api.users.update(user.id, { tracking })
    user.tracking = tracking
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async updateOnboardingStep ({ commit, rootState }, step) {
    const payload = { onboardingStep: step }

    if (step === 'terms-accepted') {
      payload.termsAccepted = true
    }

    const user = await this.$api.users.update(rootState.auth.user.id, payload)
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async createApiToken ({ commit, rootState }) {
    const user = await this.$api.users.update(rootState.auth.user.id, { apiToken: true })
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async deleteApiToken ({ commit, rootState }) {
    const user = await this.$api.users.update(rootState.auth.user.id, { apiToken: false })
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async enableSupportAccess ({ commit, rootState }) {
    const user = await this.$api.users.update(rootState.auth.user.id, { supportAccess: true })
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async revokeSupportAccess ({ commit, rootState }) {
    const user = await this.$api.users.update(rootState.auth.user.id, { supportAccess: false })
    commit('auth/SET', { key: 'user', value: user }, { root: true })
  },

  async enableAdditionalResponses ({ commit, rootState }) {
    const updatedUser = await this.$api.users.update(rootState.auth.user.id, { addOns: { additionalResponses: true } })
    commit('auth/SET', { key: 'user', value: updatedUser }, { root: true })
  },

  async disableAdditionalResponses ({ commit, rootState }) {
    const updatedUser = await this.$api.users.update(rootState.auth.user.id, { addOns: { additionalResponses: false } })
    commit('auth/SET', { key: 'user', value: updatedUser }, { root: true })
  },

  resetWorkspaces ({ commit }) {
    commit('RESET_STATE')
  }
}
