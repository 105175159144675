
import formsMixin from '~/lib/mixins/forms.mixin'

export default {
  mixins: [formsMixin],

  data () {
    return {
      model: {
        name: ''
      }
    }
  },

  methods: {
    async onSubmit () {
      try {
        this.$nuxt.$loading.start()
        this.clearErrors()

        const workspaceId = await this.$store.dispatch('account/createWorkspace', this.model.name)
        this.gotoWorkspace(workspaceId)

        await this.$emit('close')

        this.$nuxt.$loading.finish()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
