
export default {
  props: {
    profilePhotoUrl: {
      type: String,
      default () {
        return ''
      }
    },
    name: {
      type: String,
      default () {
        return ''
      }
    },
    email: {
      type: String,
      default () {
        return ''
      }
    },
    id: {
      type: String,
      default () {
        return ''
      }
    },
    revocable: {
      type: Boolean,
      default () {
        return false
      }
    },
    showOwnerBadge: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  }
}
