
import countries from '~/lib/utils/countries.utils'

const languageToCountry = {
  en: 'US',
  'en-US': 'US',
  'en-GB': 'GB',
  es: 'ES',
  'es-ES': 'ES',
  'es-MX': 'MX',
  'es-AR': 'AR',
  'es-CL': 'CL',
  'es-CO': 'CO',
  'es-PE': 'PE',
  'es-VE': 'VE',
  'es-EC': 'EC',
  'es-UY': 'UY',
  'es-BO': 'BO',
  'es-PY': 'PY',
  'es-SV': 'SV',
  'es-NI': 'NI',
  'es-HN': 'HN',
  'es-CR': 'CR',
  'es-PA': 'PA',
  'es-DO': 'DO',
  'es-GT': 'GT',
  'es-CU': 'CU',
  'es-PR': 'PR',
  'es-US': 'US',
  pt: 'BR',
  'pt-BR': 'BR',
  'pt-PT': 'PT',
  fr: 'FR',
  'fr-FR': 'FR'
}

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    defaultLanguage: {
      type: String,
      default: ''
    },
    validation: {
      type: String,
      default: ''
    },
    validationName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      countries,
      phone: '',
      countryCode: 'US',
      searchQuery: ''
    }
  },

  computed: {
    selectedCountry () {
      return this.countries.find(country => country.iso2 === this.countryCode)
    },
    filteredCountries () {
      if (!this.searchQuery) { return this.countries };
      const searchLower = this.searchQuery.toLowerCase()
      return this.countries.filter(country => country.name.toLowerCase().includes(searchLower))
    }
  },

  watch: {
    countryCode (newCode) {
      const oldDialCode = `+${this.countries.find(country => country.iso2 === newCode).dialCode}`
      if (this.phone.startsWith(oldDialCode)) {
        this.phone = this.phone.replace(oldDialCode, `+${this.selectedCountry.dialCode}`)
      } else {
        this.prependDDI()
      }
    }
  },

  mounted () {
    if (languageToCountry[this.defaultLanguage]) {
      this.countryCode = languageToCountry[navigator.language]
    }
  },

  methods: {
    handleInput () {
      if (!this.phone) {
        this.prependDDI()
      }
    },

    prependDDI () {
      if (this.phone && !this.phone.startsWith(`+${this.selectedCountry.dialCode}`)) {
        this.phone = `+${this.selectedCountry.dialCode} ${this.phone}`
      }
      this.$emit('input', this.phone)
    }
  }
}
