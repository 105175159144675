
import { stringToColor } from '~/lib/utils/colors.utils'

export default {
  name: 'UserAvatar',
  props: {
    name: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    photoUrl: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    showOwnerBadge: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      imageFailed: false
    }
  },
  computed: {
    initials () {
      if (!this.name || this.name.length < 2) {
        return '?'
      }

      // get initials from full name
      if (this.name.indexOf(' ')) {
        return this.name.split(' ').map(word => word[0]).join('').substring(0, 2)
      } else {
        return this.name.substring(0, 2)
      }
    },

    bgColor () {
      return stringToColor(this.name).hex || '#2c2c2e'
    },

    tooltip () {
      return this.showOwnerBadge ? `${this.name} (${this.$t('components.common.userAvatar.owner')})` : this.name
    }
  },
  methods: {
    onImageError () {
      this.imageFailed = true
    },
    onImageLoad () {
      this.imageFailed = false
    }
  }
}
