import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import VueFormulateAutocomplete from '~/components/common/VueFormulateAutoComplete.vue'
import PhoneInput from '~/components/common/PhoneInput.vue'
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import VueFormulateHelpRenderer from '~/components/common/VueFormulateHelpRenderer.vue'
import VueFormulateOneTimePassword from '~/components/common/VueFormulateOneTimePassword.vue'
import { hasDuplicates } from '~/lib/utils/object.utils'
import { getVideoIdFromUrl } from '~/lib/utils/embed.utils'

Vue.component('VueFormulateAutocomplete', VueFormulateAutocomplete)
Vue.component('VueFormulateOneTimePassword', VueFormulateOneTimePassword)
Vue.component('VueFormulateHelpRenderer', VueFormulateHelpRenderer)
Vue.component('PhoneInput', PhoneInput)

Vue.use(VueFormulate, {
  slotComponents: {
    help: 'VueFormulateHelpRenderer'
  },
  library: {
    autocomplete: {
      classification: 'text',
      component: 'VueFormulateAutocomplete'
    },
    onetimepassword: {
      classification: 'text',
      component: 'VueFormulateOneTimePassword'
    },
    credentialsdropdown: {
      classification: 'dropdown',
      component: 'VueFormulateCredentialsDropdown',
      slotProps: {
        component: ['credentialId', 'credentialLogo', 'credentialType', 'provider', 'integrationType']
      }
    },
    formvariable: {
      classification: 'text',
      component: 'VueFormulateFormVariable',
      slotProps: {
        component: ['varType']
      }
    }
  },
  rules: {
    onlyUniqueItems (context) {
      if (!context.value) {
        return true
      }

      const items = context.value
        .split('\n')
        .map(option => option.trim())
        .filter(option => option !== '')

      return !hasDuplicates(items)
    },

    minCount (context, ...args) {
      return context.value
        .split('\n')
        .map(option => option.trim())
        .filter(option => option !== '')
        .length >= args[0]
    },

    validateHiddenField (context, ...args) {
      const value = context.value.toLowerCase().trim()
      const reservedWords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'fbclid']
      return !args.includes(value) && !reservedWords.includes(value)
    },

    unique (context, ...args) {
      const value = context.value.toLowerCase().trim()

      return !args.includes(value)
    },

    embedableMedia (context) {
      if (!context.value) {
        return true
      }

      const value = context.value.toLowerCase().trim()

      return getVideoIdFromUrl(value) !== undefined
    },

    imageUrl (context) {
      if (!context.value) {
        return true
      }

      function isImageUrl (url) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
      }

      function isImage (url) {
        const img = new Image()
        img.src = url
        return new Promise((resolve) => {
          img.onload = () => resolve(true)
          img.onerror = () => resolve(false)
        })
      }

      async function isImageContentType (url) {
        try {
          const res = await fetch(url, { method: 'HEAD' })
          return res.headers.get('Content-Type').startsWith('image')
        } catch (e) {
          return false
        }
      }

      const url = context.value.toLowerCase().trim()

      return isImageUrl(url) || isImageContentType(url) || isImage(url)
    }
  }
})

export default {}
