/**
 * Retuns a list of error messages.
 * @param {*} error An error object, string or error returned by the api.
 * @returns Array of error messages.
 */
export function normalizeError (error) {
  // TODO: uncomment it after the api response be standardized
  // return error.response
  //   ? error.response.data.errors.map(e => e.message)
  //   : [error.message]

  if (typeof error === 'string') {
    return [error]
  }

  if (!error.response) {
    return [error.message]
  }

  if (error.response.data.message) {
    return [error.response.data.message]
  }

  return Array.isArray(error.response.data.errors)
    ? error.response.data.errors.map(e => e.message)
    : [error.response.data.errors.message]
}
