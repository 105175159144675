function componentToHex (c) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

function rgbToHex (r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export function stringToColor (inputString) {
  let sum = 0

  for (const i in inputString) {
    sum += inputString.charCodeAt(i)
  }

  const r = ~~(('0.' + Math.sin(sum + 1).toString().substring(6)) * 256)
  const g = ~~(('0.' + Math.sin(sum + 2).toString().substring(6)) * 256)
  const b = ~~(('0.' + Math.sin(sum + 3).toString().substring(6)) * 256)

  const rgb = 'rgb(' + r + ', ' + g + ', ' + b + ')'
  const hex = rgbToHex(r, g, b)

  return { rgb, hex }
}
