export default ({ $axios }) => {
  /**
   * Requests the cookie required to make requests to the API.
   *
   * @returns The cookie required to make requests to the API.
   */
  async function getCsrfCookie () {
    // return await $axios.$get('auth/csrf-cookie')
  }

  /**
   * Raw method to post a json to the given URL.
   *
   * @param {String} [url] The url to be appended to the collection.
   * @param {{password, name, timezoneName, language, affiliate, email}} [body] The body to be sent.
   * @param {String} [autenticated] (Optional) Define whether the authentication token must be sent or not.
   * @returns {Object}
   */
  async function post (url, body, _autenticated = true) {
    // $axios.setToken(autenticated)
    return (await $axios.$post(url, body)).data
  }

  /**
   * Raw method to post a json to the given URL.
   *
   * @param {String} [url] The url to be appended to the collection.
   * @param {String}[formData] The formData to be sent.
   * @returns {Object}
   */
  async function postData (url, formData) {
    return (await $axios.$post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })).data
  }
  /**
   * Finds all items of the given collection.
   *
   * @param {String} collection
   * @returns {Array}
   */
  async function find (collection, filters = {}) {
    return await $axios.$get(collection, { params: filters })
  }

  /**
   * Finds one entity of the given collection.
   *
   * @param {String} collection
   * @param {String} entityId
   * @returns {Object}
   */
  async function findOne (collection, entityId, filters = {}) {
    return (await $axios.$get(`${collection}/${entityId}`, { params: filters })).data
  }

  /**
   * Creates a new entity with timestamps on the collection.
   *
   * @param {String} collection
   * @param {Object} entity The object to be added to the collection.
   * @returns {Object}
   */
  async function create (collection, entity) {
    return (await $axios.$post(collection, entity)).data
  }

  /**
   * Updates an entity and sets the updateAt field.
   *
   * @param {String} collection
   * @param {Object} entityId
   * @param {Object} entity The object to be updated to the collection.
   * @returns {Object} entity.
   */
  async function update (collection, entityId, entity) {
    return (await $axios.$put(`${collection}/${entityId}`, entity)).data
  }

  /**
   * Sets the deletedAt field to now.
   *
   * @param {String} collection
   * @param {Object} entityId The id of the entity.
   * @returns {Object} Returns the deleted object.
   */
  async function del (collection, entityId) {
    return (await $axios.$delete(`${collection}/${entityId}`)).data
  }

  /**
   * Sets the deletedAt field to null.
   *
   * @param {Object} entityId The id of the entity.
   * @returns {Object} Returns the restaured object.
   */
  async function undoDeletion (entityId) {
    return (await new Exception('Not implemented method' + entityId)).data
  }

  return {
    post,
    postData,
    find,
    findOne,
    create,
    update,
    delete: del,
    undoDeletion,
    getCsrfCookie
  }
}
