import dayjs from 'dayjs'
import useFormsService from '~/lib/services/api/forms.service'
import useFieldsService from '~/lib/services/api/fields.service'
import hiddenFields from '~/lib/store/forms/hidden-fields'
import tracking from '~/lib/store/forms/tracking'
import variables from '~/lib/store/forms/variables'
import integrations from '~/lib/store/forms/integrations'
import fields from '~/lib/store/forms/fields'

import { isArrayEmpty } from '~/lib/utils/object.utils'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const state = () => ({
  form: null,
  fields: [],
  currentFieldId: null,
  integrations: []
})

export const getters = {
  ...fields.getters,
  ...integrations.getters,
  ...variables.getters,
  ...tracking.getters,
  ...hiddenFields.getters
}

export const mutations = {
  ...hiddenFields.mutations,
  ...variables.mutations,
  ...tracking.mutations,
  ...integrations.mutations,
  ...fields.mutations,

  SET_FORM (state, form) {
    state.form = form
  },

  UPDATE_FORM_TITLE (state, title) {
    state.form.title = title
  },

  UPDATE_FORM_STATUS (state, status) {
    state.form.status = status
  },

  UPDATE_FORM_THEME (state, theme) {
    state.form.theme = theme
  },

  UPDATE_FORM_LANGUAGE (state, language) {
    state.form.language = language
  },

  UPDATE_FORM_SETTINGS (state, settings) {
    state.form.settings = settings
  },

  UPDATE_FORM_PUBLISHED_AT (state, publishedAt) {
    state.form.publishedAt = publishedAt
  },

  UPDATE_FORM_IS_DIRTY (state, isDirty) {
    state.form.isDirty = isDirty
  },

  RESET_STATE (state) {
    state.form = null
    state.fields = []
    state.currentFieldId = null
    state.integrations = []
  }
}

export const actions = {
  ...hiddenFields.actions,
  ...variables.actions,
  ...tracking.actions,
  ...integrations.actions,
  ...fields.actions,

  async retrieveForm ({ commit, dispatch, state }, formId) {
    if (state.form && !isArrayEmpty(state.fields)) {
      commit('SET_FIRST_FIELD_AS_CURRENT')

      return state.fields[0]
    }

    return await dispatch('forceRetrieveForm', formId)
  },

  async forceRetrieveForm ({ commit }, formId) {
    const formService = useFormsService({ $axios: this.$axios })
    const form = await formService.findOne(formId)

    commit('SET_FORM', form)

    const fieldService = useFieldsService({ $axios: this.$axios })
    const fields = await fieldService.find(formId)

    commit('SET_FIELDS', fields)
    commit('SET_FIRST_FIELD_AS_CURRENT')

    return fields[0]
  },

  async updateFormTitle ({ commit, state }, title) {
    const formService = useFormsService({ $axios: this.$axios })
    await formService.update(state.form.id, { title })

    commit('UPDATE_FORM_TITLE', title)
  },

  async updateFormTheme ({ commit, state }, theme) {
    const formService = useFormsService({ $axios: this.$axios })
    await formService.update(state.form.id, { themeId: theme.id })

    commit('UPDATE_FORM_THEME', theme)
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFormLanguage ({ commit, state }, language) {
    const formService = useFormsService({ $axios: this.$axios })
    await formService.update(state.form.id, { language })

    commit('UPDATE_FORM_LANGUAGE', language)
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFormSetting ({ commit, state }, { setting, value }) {
    const settings = { ...state.form.settings }
    settings[setting] = value
    // const formService = useFormsService({ $axios: this.$axios })
    // await formService.update(state.form.id, { settings })
    await this.$api.forms.update(state.form.id, { settings })

    commit('UPDATE_FORM_SETTINGS', settings)
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async publishForm ({ commit, state }, publishDate) {
    const formService = useFormsService({ $axios: this.$axios })
    const publishedAt = dayjs(publishDate).utc().format('YYYY-MM-DD HH:mm:ss')
    const form = await formService.update(state.form.id, { action: 'publish' })

    commit('UPDATE_FORM_STATUS', form.status)
    commit('UPDATE_FORM_PUBLISHED_AT', publishedAt)
    commit('UPDATE_FORM_IS_DIRTY', false)
  },

  async discardForm ({ dispatch, state }) {
    const formService = useFormsService({ $axios: this.$axios })
    await formService.update(state.form.id, { action: 'discard' })

    return await dispatch('forceRetrieveForm', state.form.id)
  }
}
