import { replaceAll, removeTagsByAttribute } from '~/lib/utils/string.utils'

const actions = {
  async addVariable ({ commit, state }) {
    const getNextVariableName = (lastIndex) => {
      let nextVariableName = `variable_${lastIndex}`
      const alreadyExists = (state.form.variables || []).findIndex(variable => variable.name === nextVariableName) > -1

      if (alreadyExists) {
        nextVariableName = getNextVariableName(lastIndex + 1)
      }

      return nextVariableName.toLowerCase()
    }

    const name = getNextVariableName((state.form.variables || []).length + 1)
    const variable = { name, type: 'number', value: 0 }

    commit('ADD_VARIABLE', variable)
    const variables = state.form.variables
    await this.$api.forms.update(state.form.id, { variables })
    return variable
  },

  async deleteVariable ({ commit, state, dispatch }, name) {
    const index = state.form.variables.findIndex(v => v.name === name)
    dispatch('removeRulesFromFieldLogicContainingVariable', name)
    dispatch('removeCalendlyPrefill', { type: 'variable', item: name })
    dispatch('removeVariableFromFields', name)
    commit('DELETE_VARIABLE', index)
    const variables = state.form.variables
    await this.$api.forms.update(state.form.id, { variables })
  },

  async updateVariableName ({ commit, state, dispatch }, { oldName, newName }) {
    const index = state.form.variables.findIndex(v => v.name === oldName)

    const normalizeName = (name) => {
      return name.trim().toLowerCase()
    }

    newName = normalizeName(newName)

    dispatch('replaceVariableNameOnRules', { oldName, newName })
    dispatch('replaceVariableNameOnFields', { oldName, newName })
    commit('UPDATE_VARIABLE', { index, key: 'name', value: newName })
    const variables = state.form.variables
    await this.$api.forms.update(state.form.id, { variables })
  },

  async updateVariableValue ({ commit, state }, { varName, value }) {
    const index = state.form.variables.findIndex(v => v.name === varName)

    const normalizeValue = (type, value) => {
      if (type === 'number') {
        return Number(value)
      }
      if (type === 'boolean') {
        return Boolean(value)
      }
      return value
    }

    value = normalizeValue(state.form.variables[index].type, value)
    commit('UPDATE_VARIABLE', { index, key: 'value', value })
    const variables = state.form.variables
    await this.$api.forms.update(state.form.id, { variables })
  },
  removeRulesFromFieldLogicContainingVariable ({ state, commit }, varName) {
    // When a variable deleted, all rules containing the variable should be removed from all fields
    const anyConditionUseVariable = rule => rule.conditions.some(action => action.variableName === varName)
    const anyActionUseVariable = rule => rule.actions.some(action => action.variable === varName)
    const fieldsWithThisVariableInLogic = state.fields.filter(field => (field.logic || []).some(rule => anyConditionUseVariable(rule) || anyActionUseVariable(rule)))

    fieldsWithThisVariableInLogic.forEach((field) => {
      // remove rules containing the variable from the field logic
      let logic = [...field.logic.filter(rule => !(anyConditionUseVariable(rule) || anyActionUseVariable(rule)))]

      if (logic.length === 0) { logic = null }

      this.$api.fields.update(field.id, { logic })
      commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
    })

    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  replaceVariableNameOnRules ({ state, commit }, { oldName, newName }) {
    // When a variable name is renamed, all rules containing the variable should be updated to use the new name
    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      if (!Array.isArray(field.logic)) {
        continue
      }

      const logic = JSON.parse(JSON.stringify(field.logic))
      let updateLogic = false

      // update rules containing the oldName from the field logic
      for (let i2 = 0; i2 < logic.length; i2++) {
        const rule = logic[i2]

        for (let i3 = 0; i3 < rule.actions.length; i3++) {
          const action = rule.actions[i3]

          if (action.variable === oldName) {
            logic[i2].actions[i3].variable = newName
            updateLogic = true
          }
        }

        for (let i3 = 0; i3 < rule.conditions.length; i3++) {
          const condition = rule.conditions[i3]

          if (condition.variableName === oldName) {
            logic[i2].conditions[i3].variableName = newName
            updateLogic = true
          }
        }
      }

      if (updateLogic) {
        this.$api.fields.update(field.id, { logic })
        commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
      }
    }
  },

  replaceVariableNameOnFields ({ state, commit }, { oldName, newName }) {
    // When a variable name is renamed, all fields containing the variable
    // in the title or description should be updated to use the new name

    const dataId = varName => `data-id="${varName}"`
    const replaceContent = (content) => {
      // content example:
      // <p>Score: <span data-type="mention" class="mention-variable" data-id="score">@score</span>
      // Variable: <span data-type="mention" class="mention-variable" data-id="variable">@variable</span></p>
      content = replaceAll(content, dataId(oldName), dataId(newName))
      content = replaceAll(content, `data-label="${oldName}"`, `data-label="${newName}"`)
      content = replaceAll(content, `>${oldName}<`, `>${newName}<`)
      content = replaceAll(content, `>@${oldName}<`, `>@${newName}<`)

      return content
    }

    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains the oldName
      if (field.title && field.title.includes(dataId(oldName))) {
        const title = replaceContent(field.title)
        this.$api.fields.update(field.id, { title })
        commit('UPDATE_FIELD_TITLE', { fieldId: field.id, title })
        formMustBeDirty = true
      }

      // update description if it contains the oldName
      if (field.description && field.description.includes(dataId(oldName))) {
        const description = replaceContent(field.description)
        this.$api.fields.update(field.id, { description })
        commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
        formMustBeDirty = true
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  },

  async removeVariableFromFields ({ state, commit, dispatch }, varName) {
    // remove tags span with data-id="${varName}" from fields title and description
    // for each field, if title or description contains the variable, update the field
    const dataId = `data-id="${varName}"`
    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains the varName
      if (field.title && field.title.includes(dataId)) {
        const title = removeTagsByAttribute(field.title, 'data-id', varName)
        this.$api.fields.update(field.id, { title })
        await dispatch('updateFieldTitle', { fieldId: field.id, title })
        // commit('UPDATE_FIELD_TITLE', { fieldId: field.id, title })
        formMustBeDirty = true
      }

      // update description if it contains the varName
      if (field.description && field.description.includes(dataId)) {
        const description = removeTagsByAttribute(field.description, 'data-id', varName)
        this.$api.fields.update(field.id, { description })
        commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
        formMustBeDirty = true
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  }
}

const mutations = {
  ADD_VARIABLE (state, variable) {
    if (state.form.variables === null) {
      state.form.variables = []
    }
    state.form.variables.push(variable)
  },

  DELETE_VARIABLE (state, index) {
    if (state.form.variables === null) {
      state.form.variables = []
    }
    state.form.variables.splice(index, 1)
  },

  UPDATE_VARIABLE (state, { index, key, value }) {
    const variable = state.form.variables[index]
    variable[key] = value
    state.form.variables.splice(index, 1, variable)
  }
}

const getters = {
  isVariableUsedInLogic: state => (varName) => {
    const anyActionUse = logic => logic.some(rule => rule.actions.some(action => action.variable === varName))

    return state.fields.some(field => field.logic && (anyActionUse(field.logic)))
  },

  isVariableUsedOnContent: state => (varName) => {
    const dataId = `data-id="${varName}"`

    return state.fields.some(field => field.title?.includes(dataId) || field.description?.includes(dataId))
  },

  defaultVariables (state) {
    return (state.form.variables || []).filter(v => v.name === 'score')
  },

  customVariables (state) {
    return (state.form.variables || []).filter(v => v.name !== 'score')
  },

  getVariableByName: state => (name) => {
    return (state.form.variables || []).find(v => v.name === name)
  },

  getVariablesNames: (state) => {
    return (state.form.variables || []).map(v => v.name)
  },
  getVariables: (state) => {
    return (state.form.variables || []).map(v => v)
  }
}

export default {
  actions,
  mutations,
  getters
}
