
import ModalAddWorkspace from '~/components/workspace/ModalAddWorkspace.vue'
import formsMixin from '~/lib/mixins/forms.mixin'
import authorizationMixin from '~/lib/mixins/authorization.mixin'

export default {
  name: 'WorkspacesDropdown',
  mixins: [formsMixin, authorizationMixin],

  async fetch () {
    await this.$store.dispatch('account/retrieveWorkspaces')
    await this.ensureUserCanAccessWorkspace(this.$store.getters['account/currentWorkspaceId'])
  },

  computed: {
    workspace: {
      get () {
        return this.$store.getters['account/getWorkspaceById'](this.$route.params.workspaceid)
      },
      set (newWorkspace) {
        !newWorkspace
          ? this.openAddWorkspaceDialog()
          : this.changeCurrentWorkspace(newWorkspace)
      }
    },
    workspaces () {
      return this.$store.state.account.workspaces
    }
  },

  methods: {
    async changeCurrentWorkspace (workspace) {
      try {
        this.clearErrors()
        await this.$store.dispatch('account/changeWorkspace', workspace.id)

        const routesToRedirect = ['/workspaces/new']

        routesToRedirect.includes(this.$route.path)
          ? this.gotoHome()
          : this.gotoWorkspace(workspace.id)
      } catch (error) {
        this.handleError(error)
      }
    },

    async openAddWorkspaceDialog () {
      await this.$oruga.modal.open({
        parent: this,
        component: ModalAddWorkspace,
        custom: true,
        trapFocus: true
      })
    }
  }
}
