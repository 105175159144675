
import { mapState } from 'vuex'
import { abbreviateNumber } from '~/lib/utils/string.utils'
import ModalPlans from '~/components/account/ModalPlans.vue'

export default {
  props: {
    showUsage: {
      type: Boolean,
      default: false
    }
  },

  fetch () {
    this.$store.dispatch('account/retrieveBillingStatus')
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      currentPlan: state => state.account.subscription?.currentPlan,
      planFeatures: state => state.account.planFeatures
    }),
    planUsage () {
      return abbreviateNumber(this.planFeatures.responses.used)
    },
    planLimit () {
      return abbreviateNumber(this.planFeatures.responses.limit)
    },
    showUpgradeButton () {
      return this.currentPlan !== 'professional'
    }
  },
  methods: {
    openPlansModal () {
      this.$oruga.modal.open({
        parent: this,
        component: ModalPlans,
        custom: true,
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        overlayClass: 'bg-neutral-50'
      })
    },

    async gotoBillingPortal () {
      this.loading = true
      const { url } = await this.$api.billing.getPortal(window.location.href)
      window.location.href = url
      // this.loading = false
    }
  }
}
