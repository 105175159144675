import useAuthService from '~/lib/services/api/auth.service';
import useBillingService from '~/lib/services/api/billing.service';
import useCredentialsService from '~/lib/services/api/credentials.service';
import useFieldsService from '~/lib/services/api/fields.service';
import useFormsService from '~/lib/services/api/forms.service';
import useThemesService from '~/lib/services/api/themes.service';
import useUsersService from '~/lib/services/api/users.service';
import useWorkspacesService from '~/lib/services/api/workspaces.service';
import useAnswersService from '~/lib/services/api/answers.service';
import useResponsesService from '~/lib/services/api/responses.service';
import useInsightsService from '~/lib/services/api/insights.service';
import useRecipesService from '~/lib/services/api/recipes.service';
import useMediasService from '~/lib/services/api/medias.service';
import useIntegrationsService from '~/lib/services/api/integrations.service';
import useIntegrationSchemasService from '~/lib/services/api/integration-schemas.service';
import useLogsService from '~/lib/services/api/logs.service'; // https://nuxtjs.org/docs/2.x/internals-glossary/context/

export default (function (_ref, inject) {
  var $axios = _ref.$axios;
  inject('api', {
    answers: useAnswersService({
      $axios: $axios
    }),
    auth: useAuthService({
      $axios: $axios
    }),
    billing: useBillingService({
      $axios: $axios
    }),
    credentials: useCredentialsService({
      $axios: $axios
    }),
    fields: useFieldsService({
      $axios: $axios
    }),
    forms: useFormsService({
      $axios: $axios
    }),
    themes: useThemesService({
      $axios: $axios
    }),
    responses: useResponsesService({
      $axios: $axios
    }),
    users: useUsersService({
      $axios: $axios
    }),
    workspaces: useWorkspacesService({
      $axios: $axios
    }),
    insights: useInsightsService({
      $axios: $axios
    }),
    recipes: useRecipesService({
      $axios: $axios
    }),
    medias: useMediasService({
      $axios: $axios
    }),
    integrations: useIntegrationsService({
      $axios: $axios
    }),
    integrationSchemas: useIntegrationSchemasService({
      $axios: $axios
    }),
    logs: useLogsService({
      $axios: $axios
    })
  });
});