
const variants = {
  error: { icon: 'oi-smiley-shook', color: 'bg-neutral-lightest' },
  success: { icon: 'oi-check-circle-1', color: 'bg-green-lightest' },
  empty: { icon: 'oi-cursor-double-click-3', color: 'bg-neutral-lightest' }
}

export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: 'error',
      validator: value => Object.keys(variants).includes(value)
    },
    message: {
      type: String,
      default () {
        this.$t('components.common.stateBlock.default')
      }
    },
    icon: {
      type: String,
      default: ''
    }
  },

  computed: {
    variantIcon () {
      return this.icon || variants[this.variant].icon
    },
    variantBackground () {
      return variants[this.variant].color
    }
  }
}
