export default {
  methods: {
    async ensureUserCanAccessWorkspace (workspaceId) {
      const workspaceExists = this.$store.getters['account/getWorkspaceById'](workspaceId) !== undefined

      if (!workspaceExists) {
        const firstWorkspaceId = this.$store.getters['account/getFirstWorkspaceId']
        await this.$store.dispatch('account/changeWorkspace', firstWorkspaceId)
        this.gotoWorkspace(firstWorkspaceId)
      }
    }
  }
}
