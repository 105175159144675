
import ModalAffiliate from '~/components/account/ModalAffiliate.vue'
import ModalPlans from '~/components/account/ModalPlans.vue'

export default {
  name: 'UserAvatarDropdown',

  props: {
    showName: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    signOut () {
      this.$router.push('/auth/signout')
    },

    async gotoBillingPortal () {
      this.loading = true
      const { url } = await this.$api.billing.getPortal(window.location.href)
      window.location.href = url
    },

    openAffiliateModal () {
      this.$oruga.modal.open({
        parent: this,
        component: ModalAffiliate,
        custom: true,
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        overlayClass: 'bg-neutral-50'
      })
    },

    openPlansModal () {
      this.$oruga.modal.open({
        parent: this,
        component: ModalPlans,
        custom: true,
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        overlayClass: 'bg-neutral-50'
      })
    }
  }
}
