import AlertDialog from '~/components/common/AlertDialog.vue'
import ConfirmationDialog from '~/components/common/ConfirmationDialog.vue'

export class DialogService {
  alert (vue, message, title = 'Hey!', onOk = () => { }, okButtonText = 'Ok, got it!') {
    vue.$oruga.modal.open({
      component: AlertDialog,
      props: {
        title,
        message,
        onOk,
        okButtonText
      },
      custom: true,
      trapFocus: true
    })
  }

  confirm (vue, { title = 'Wait a minute!', message, noButtonText = 'No', yesButtonText = 'Yes', variant = 'danger' }, onConfirmed) {
    vue.$oruga.modal.open({
      component: ConfirmationDialog,
      props: {
        title,
        message,
        variant,
        noButtonText,
        yesButtonText,
        onConfirmed
      },
      custom: true,
      trapFocus: true
    })
  }

  confirmDeletion (vue, message, onConfirmed) {
    vue.$oruga.modal.open({
      parent: vue,
      component: ConfirmationDialog,
      props: {
        message,
        noButtonText: 'Cancel',
        yesButtonText: 'Delete',
        onConfirmed
      },
      custom: true,
      trapFocus: true
    })
  }

  confirmUpgrade (vue, title, message) {
    vue.$oruga.modal.open({
      parent: vue,
      component: ConfirmationDialog,
      props: {
        title,
        message,
        noButtonText: 'I\'ll do it later',
        yesButtonText: 'View plans',
        icon: 'oi-flash',
        variant: 'warning',
        onConfirmed: async () => {
          const { url } = await vue.$api.billing.getPortal(window.location.href)
          window.location.href = url
        }
      },
      custom: true,
      trapFocus: true
    })
  }
}
