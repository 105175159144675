
export default {
  props: {
    max: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: 0
    },
    bgClass: {
      type: String,
      default: 'w-2 p-0.5 bg-neutral-lighter'
    },
    alwaysShowProgress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    progress () {
      let result = this.value * 100 / this.max
      if (this.alwaysShowProgress && result < 1) {
        result += 1 // add 1 to show at least one pixel height for the progress bar
      }
      if (result > 100) { result = 100 }
      return result + '%'
    }
  }
}
