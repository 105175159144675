import { render, staticRenderFns } from "./TheNavbarAlert.vue?vue&type=template&id=140d60bc&"
import script from "./TheNavbarAlert.vue?vue&type=script&lang=js&"
export * from "./TheNavbarAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack-edge/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VAlert: require('/workspace/components/common/VAlert.vue').default})
