
export default {
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return 'Hey!'
      }
    },
    message: {
      type: String,
      required: true
    },
    okButtonText: {
      type: String,
      required: false,
      default () {
        return this.$t('components.common.alertDialog.ok')
      }
    },
    onOk: {
      type: Function,
      required: false,
      default: () => {}
    }
  },

  methods: {
    okClick () {
      this.$emit('close')

      this.onOk()
    }
  }
}
