export function isArrayEmpty (object) {
  return (Array.isArray(object) && !object.length)
}

export function hasDuplicates (array) {
  return (new Set(array)).size !== array.length
}

export function randomKey (suffix, index) {
  return `${suffix}-${index}-${Math.floor(Math.random() * 1000)}`
}

export function groupBy (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
