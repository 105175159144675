
import formsMixin from '~/lib/mixins/forms.mixin'

export default {
  mixins: [formsMixin],

  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      model: {
        name: ''
      }
    }
  },

  methods: {
    async onSubmit () {
      try {
        this.$nuxt.$loading.start()
        this.clearErrors()

        await this.$store.dispatch('account/deleteWorkspace', this.id)
        await this.$store.dispatch('account/retrieveWorkspaces')

        const firstWorkspaceId = this.$store.getters['account/getFirstWorkspaceId']
        this.gotoWorkspace(firstWorkspaceId)

        await this.$emit('close')

        this.$nuxt.$loading.finish()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
