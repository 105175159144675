import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'user' })

  /**
   * Returns all users of a given workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<Object>>} Returns an array of users.
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/users`)).data
  }

  /**
   * Returns a user with the given userId.
   *
   * @param {String} workspaceId
   * @param {String} userId
   * @returns {Promise<{Object>} Returns a user.
   */
  async function findOne (userId) {
    return await repository.findOne('users', userId)
  }

  /**
   * Updates the current workspace of the user.
   *
   * @param {String} userId
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the updated user.
   */
  async function changeWorkspace (userId, workspaceId) {
    return await repository.update('users', userId, { currentWorkspaceId: workspaceId })
  }

  /**
   * Updates an user.
   *
   * @param {String} userId
   * @param {Object} properties
   * @returns {Promise<Object>} Returns the updated user.
   * @memberof UsersService
   */
  async function update (userId, properties) {
    return await repository.update('users', userId, properties)
  }

  /**
   * Sets the deletedAt attribute of the given user to the current date and time.
   *
   * @param {String} workspaceId
   * @param {String} userId
   * @returns {Promise<string>} Returns the id of the deleted user.
   */
  async function del (workspaceId, userId) {
    return await repository.delete(`workspaces/${workspaceId}/users`, userId)
  }

  /**
   * Accept invitation
   *
   * @param {String} id
   * @param {String} signature
   * @returns {Promise<Object>} Returns the invitation and user associated.
   */
  async function acceptInvitation (id, signature) {
    return await repository.create(`invitations/${id}`, { signature })
  }

  return {
    acceptInvitation,
    changeWorkspace,
    delete: del,
    update,
    find,
    findOne
  }
}
