
export default {
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return this.$t('components.common.confirmationDialog.wait')
      }
    },
    message: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: false,
      default () {
        return 'danger'
      }
    },
    noButtonText: {
      type: String,
      required: false,
      default () {
        return this.$t('components.common.confirmationDialog.no')
      }
    },
    yesButtonText: {
      type: String,
      required: false,
      default () {
        return this.$t('components.common.confirmationDialog.yes')
      }
    },
    icon: {
      type: String,
      required: false,
      default () {
        return 'oi-road-sign-warning'
      }
    },
    onConfirmed: {
      type: Function,
      required: false,
      default: () => {}
    }
  },

  computed: {
    iconColor () {
      return this.variant === 'danger' ? 'text-red' : 'text-neutral-darkest'
    }
  },

  methods: {
    okClick () {
      this.$emit('close')

      this.onConfirmed()
    }
  }
}
