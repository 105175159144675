import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'insights' })

  /**
   * Returns all insights of a given field.
   *
   * @param {String} fieldId
   * @returns {Promise<Array<{Object}>>} Returns an array of insights.
   * @memberof InsightsService
   */
  async function find (fieldId) {
    return (await repository.find(`fields/${fieldId}/insights`)).data
  }

  /**
  * Calculates the number of insights that can be generate between two dates.
  *
  * @param {String} fieldId
  * @param {String} startsAt
  * @param {String} endsAt
  * @returns {Promise<Array<{Object}>>} Returns an array of insights.
  * @memberof InsightsService
  */
  async function getSummary (fieldId, startsAt, endsAt) {
    const startsAtParam = startsAt ? `&startsAt=${startsAt}` : ''
    const endsAtParam = endsAt ? `&endsAt=${endsAt}` : ''

    return (await repository.find(`fields/${fieldId}/insights?options=summary${startsAtParam}${endsAtParam}`)).data

    // const startsAtParam = startsAt ? `&startsAt=${toApiDateFormat(startsAt)}` : ''
    // const endsAtParam = endsAt ? `&endsAt=${toApiDateFormat(endsAt)}` : ''

    // return (await repository.find(`fields/${fieldId}/insights?options=summary${startsAtParam}${endsAtParam}`)).data
  }

  /**
   * Returns a insight with the given insightId.
   *
   * @param {String} workspaceId
   * @param {String} insightId
   * @returns {Promise<{Object}>} Returns a insight.
   * @memberof InsightsService
   */
  async function findOne (insightId) {
    return await repository.findOne('insights', insightId)
  }

  /**
   * Creates a new insight.
   *
   * @param {String} fieldId
   * @returns {Promise<Object>} Returns the new insight created.
   * @memberof InsightsService
   */
  async function create (fieldId, recipeId, quantity, additionalInstructions, startsAt, endsAt) {
    return await repository.create(`fields/${fieldId}/insights`, { recipeId, quantity, additionalInstructions, startsAt, endsAt })
  }

  /**
   * Updates a insight.
   *
   * @param {String} insightId
   * @param {Object} insight
   * @returns {Promise<Object>} Returns the updated insight.
   * @memberof InsightsService
   */
  async function update (insightId, insight) {
    return await repository.update('insights', insightId, insight)
  }

  /**
   * Sets the deletedAt attribute of the given insight to the current date and time.
   *
   * @param {String} insightId
   * @returns {Promise<string>} Returns the id of the deleted insight.
   * @memberof InsightsService
   */
  async function del (insightId) {
    return await repository.delete('insights', insightId)
  }

  return {
    create,
    delete: del,
    find,
    findOne,
    getSummary,
    update
  }
}
