import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'user' })

  /**
   * Returns the billing status of the user.
   *
   * @returns {Promise<Object>} Returns the billing status of the user.
   */
  async function getStatus () {
    return (await repository.find('billing/status')).data
  }

  /**
   * Returns the billing portal URL of the user.
   *
   * @returns {Promise<Object>} Returns the billing portal URL of the user.
   */
  async function getPortal (redirectUrl) {
    return (await repository.find('billing/portal', { redirectUrl })).data
  }

  /**
   * Returns the checkout URL for either authenticated or guest user.
   *
   * @param params It accepts the following properties: plan, interval, coupon, promotionCode, successUrl, cancelUrl
   * @returns {Promise<Object>} Returns the checkout URL of the user.
   */
  async function getCheckout (params) {
    return (await repository.find('billing/checkout', params)).data
  }

  return {
    getStatus,
    getPortal,
    getCheckout
  }
}
