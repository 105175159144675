
// import { createFeedbackButton } from '~/lib/utils/yfembed.utils'

export default {
  name: 'DefaultLayout',
  // mounted () {
  //   createFeedbackButton(this.$auth.user)
  // },
  head: {
    bodyAttrs: {
      class: 'bg-neutral-50'
    }
  }
}
