// import cookie from 'cookie'

export const formLanguages = [
  {
    code: 'en',
    name: 'lib.utils.i18n.en'
  },
  {
    code: 'pt',
    name: 'lib.utils.i18n.pt'
  },
  {
    code: 'es',
    name: 'lib.utils.i18n.es'
  }
]

export const appLanguages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'pt',
    name: 'Portuguese (Brazil)'
  }
]

export function getBrowserLocale (options = {}) {
  const defaultOptions = { countryCodeOnly: false, fallbackCountryCode: 'en' }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage

  if (!navigatorLocale) {
    return fallbackCountryCode
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

  return trimmedLocale
}

export function setUserLanguageCookie (languageCode) {
  const expirationYear = new Date().getFullYear() + 1
  document.cookie = `yf_lang=${languageCode}; expires=Thu, 1 Dec ${expirationYear} 12:00:00 UTC; path=/`
}

export function getUserLanguageFromCookie () {
  return getCookieValue('yf_lang')
}

function getCookieValue (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
