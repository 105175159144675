
export default {
  props: {
    message: {
      type: String,
      default () {
        this.$t('components.common.successNotification.default')
      }
    }
  }
}
