export function pascalToKebabCase (text) {
  return text.replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function toCamelCase (text) {
  return (text || '').replace(/-\w/g, clearAndUpper)
}

export function toPascalCase (text) {
  return (text || '').replace(/(^\w|-\w)/g, clearAndUpper)
}

function clearAndUpper (text) {
  return (text || '').replace(/-/, '').toUpperCase()
}

export function isEmail (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export function secondsToHHMMSS (totalSeconds) {
  const secNum = parseInt(totalSeconds, 10)
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - (hours * 3600)) / 60)
  let seconds = secNum - (hours * 3600) - (minutes * 60)

  if (isNaN(hours)) { hours = 0 }
  if (isNaN(minutes)) { minutes = 0 }
  if (isNaN(seconds)) { seconds = 0 }

  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }

  const result = `${hours}:${minutes}:${seconds}`

  return result === '00:00:00' ? '–' : result
}

export function removeHtmlTags (str) {
  if (str === null || str === undefined || str === '') {
    return ''
  }

  str = str.toString()

  return str.replace(/(<([^>]+)>)/ig, '').trim()
}

export function abbreviateNumber (n) {
  if (n === null) { return '∞' }
  if (n < 1e3) { return n }
  if (n >= 1e3 && n < 1e6) { return +(n / 1e3).toFixed(1) + 'K' }
  if (n >= 1e6 && n < 1e9) { return +(n / 1e6).toFixed(1) + 'M' }
  if (n >= 1e9 && n < 1e12) { return +(n / 1e9).toFixed(1) + 'B' }
  if (n >= 1e12) { return +(n / 1e12).toFixed(1) + 'T' }
}

export function letter (index) {
  const alphabet = 'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,OA,P,AQ,RA,S,AT,UA,V,AW,XA,AY,AZ,BA,BB,BC,BD,BE,BF,BG,BH,BI,BJ,BK,BL,BM,BN,BO,BP,BQ,BR,BS,BT,BU,BV,BW,BX,BY,BZ,CA,CB,CC,CD,CE,CF,CG,CH,CI,CJ,CK,CL,CM,CN,CO,CP,CQ,CR,CS,CT,CU,CV,CW,CX,CY,CZ,DA,DB,DC,DD,DE,DF,DG,DH,DI,DJ,DK,DL,DM,DN,DO,DP,DQ,DR,DS,DT,DU,DV,DW,DX,DY,DZ'.split(',')
  if (index > alphabet.length) { index = 0 }
  return alphabet[index]
}

export function htmlEncode (str) {
  const p = document.createElement('p')
  p.textContent = str
  return p.innerHTML
}

export function htmlDecode (html) {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export function escapeRegExp (string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function replaceAll (str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

export function removeTagsByAttribute (html, attrName, attrValue) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const elements = doc.querySelectorAll(`[${attrName}="${attrValue}"]`)

  for (let i = elements.length - 1; i >= 0; i--) {
    elements[i].remove()
  }

  return doc.body.innerHTML
}
