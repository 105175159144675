import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'forms' })

  /**
   * Returns all forms of a given workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<{Object}>>} Returns an array of forms.
   * @memberof FormsService
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/forms`)).data
  }

  /**
   * Returns a form with the given formId.
   *
   * @param {String} workspaceId
   * @param {String} formId
   * @returns {Promise<{Object}>} Returns a form.
   * @memberof FormsService
   */
  async function findOne (formId, draft = true) {
    return await repository.findOne('forms', formId, { draft })
  }

  /**
   * Returns the stats of responses related to a given form.
   *
   * @param {String} formId
   * @returns {Promise<{Object}>} Returns stats.
   * @memberof FormsService
   */
  async function stats (formId, device) {
    return (await repository.find(`forms/${formId}/stats`, { device })).data
  }

  /**
   * Creates a new form.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the new form created.
   * @memberof FormsService
   */
  async function create (workspaceId, title) {
    return await repository.create(`workspaces/${workspaceId}/forms`, { title })
  }

  /**
   * Duplicates a form.
   *
   * @param {String} workspaceId
   * @param {String} cloneFormId
   * @returns {Promise<Object>} Returns the new form created.
   * @memberof FormsService
   */
  async function createFromAI (workspaceId, formSubject) {
    return await repository.create(`workspaces/${workspaceId}/forms`, { formSubject })
  }

  /**
   * Duplicates a form.
   *
   * @param {String} workspaceId
   * @param {String} cloneFormId
   * @returns {Promise<Object>} Returns the new form created.
   * @memberof FormsService
   */
  async function duplicate (workspaceId, cloneFormId) {
    return await repository.create(`workspaces/${workspaceId}/forms`, { cloneFormId })
  }

  /**
   * Imports a form.
   *
   * @param {String} workspaceId
   * @param {String} importFormKey
   * @returns {Promise<Object>} Returns the new form created.
   * @memberof FormsService
   */
  async function importForm (workspaceId, importFormKey) {
    return await repository.create(`workspaces/${workspaceId}/forms`, { importFormKey })
  }

  /**
   * Updates a form.
   *
   * @param {String} formId
   * @param {Object} form
   * @returns {Promise<Object>} Returns the updated form.
   * @memberof FormsService
   */
  async function update (formId, form) {
    return await repository.update('forms', formId, form)
  }

  /**
   * Sets the deletedAt attribute of the given form to the current date and time.
   *
   * @param {String} formId
   * @returns {Promise<string>} Returns the id of the deleted form.
   * @memberof FormsService
   */
  async function del (formId) {
    return await repository.delete('forms', formId)
  }

  return {
    create,
    createFromAI,
    delete: del,
    duplicate,
    import: importForm,
    find,
    findOne,
    stats,
    update
  }
}
