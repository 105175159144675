import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'credentials' })

  /**
   * Returns all credentials of the authenticated user.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<{Object}>>} Returns an array of credentials.
   * @memberof CredentialsService
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/credentials?limit=1000`)).data
  }

  /**
   * Returns a credential with the given credentialId.
   *
   * @param {String} workspaceId
   * @param {String} credentialId
   * @returns {Promise<{Object}>} Returns a credential.
   * @memberof CredentialsService
   */
  async function findOne (workspaceId, credentialId) {
    return await repository.findOne(`workspaces/${workspaceId}/credentials`, credentialId)
  }

  /**
   * Returns the auth URL given the driver.
   *
   * @param {String} integrationType
   * @returns {Promise<{Object}>} Returns the auth URL.
   * @memberof CredentialsService
   */
  async function getAuthUrl (integrationType) {
    return (await repository.find(`credentials/url?integrationType=${integrationType}`)).data
  }

  /**
   * Creates a new credential.
   *
   * @param {String} workspaceId
   * @param {String} provider
   * @param {String} params
   * @returns {Promise<Object>} Returns the new credential created.
   * @memberof CredentialsService
   */
  async function create (workspaceId, provider, params) {
    return await repository.create(`workspaces/${workspaceId}/credentials`, { provider, ...params })
  }

  /**
   * Updates a credential.
   *
   * @param {String} credentialId
   * @param {Object} credential
   * @returns {Promise<Object>} Returns the updated credential.
   * @memberof CredentialsService
   */
  async function update (credentialId, credential) {
    return await repository.update('credentials', credentialId, credential)
  }

  /**
   * Sets the deletedAt attribute of the given credential to the current date and time.
   *
   * @param {String} credentialId
   * @returns {Promise<string>} Returns the id of the deleted credential.
   * @memberof CredentialsService
   */
  async function del (credentialId) {
    return await repository.delete('credentials', credentialId)
  }

  return {
    create,
    delete: del,
    find,
    findOne,
    getAuthUrl,
    update
  }
}
