
import { mapState, mapGetters } from 'vuex'
import ModalChangeSubdomain from '~/components/workspace/ModalChangeSubdomain.vue'
import ModalRenameWorkspace from '~/components/workspace/ModalRenameWorkspace.vue'
import ModalDeleteWorkspace from '~/components/workspace/ModalDeleteWorkspace.vue'
import featuresMixin, { resources } from '~/lib/mixins/features.mixin'

export default {
  mixins: [featuresMixin],

  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    ...mapGetters({
      currentWorkspace: 'account/getCurrentWorkspace'
    }),

    workspaces () {
      return this.$store.state.account.workspaces
    },

    isWorkspaceOwner () {
      return this.currentWorkspace?.userId === this.user?.id
    },

    workspaceId () {
      return this.$route.params.workspaceid
    },

    canChangeSubdomain () {
      return this.$store.state.account.subscription && this.canUseFeature(resources.customSubdomain)
    }
  },

  methods: {
    async openRenameWorkspaceDialog () {
      const id = this.workspaceId
      const name = this.currentWorkspace?.name

      await this.$oruga.modal.open({
        parent: this,
        component: ModalRenameWorkspace,
        props: { id, name },
        custom: true,
        trapFocus: true
      })
    },

    async openChangeSubdomainDialog () {
      if (!this.canUseFeature(resources.customSubdomain, true)) {
        return
      }

      const id = this.workspaceId
      const subdomain = this.currentWorkspace?.subdomain

      await this.$oruga.modal.open({
        parent: this,
        component: ModalChangeSubdomain,
        props: { id, subdomain },
        custom: true,
        trapFocus: true
      })
    },

    async openDeleteWorkspaceDialog () {
      const id = this.workspaceId
      const name = this.currentWorkspace?.name

      await this.$oruga.modal.open({
        parent: this,
        component: ModalDeleteWorkspace,
        props: { id, name },
        custom: true,
        trapFocus: true
      })
    },

    confirmLeavingWorkspace () {
      const name = this.currentWorkspace?.name

      const confirmOptions = {
        title: this.$t('components.layout.theWorkspaceOptions.title'),
        message: `${this.$t('components.layout.theWorkspaceOptions.goingAhead')} "${name}"${this.$t('components.layout.theWorkspaceOptions.anymore')}`,
        noButtonText: this.$t('components.layout.theWorkspaceOptions.noButtonText'),
        yesButtonText: this.$t('components.layout.theWorkspaceOptions.yesButtonText')
      }

      this.$msgbox.confirm(this, confirmOptions, async () => {
        await this.$api.workspaces.deleteUser(this.workspaceId, this.$store.state.auth.user.id)
        this.$store.dispatch('account/resetWorkspaces')
        this.$store.dispatch('account/retrieveWorkspaces')
      })
    }

  }
}
