
import { mapState } from 'vuex'

export default {
  name: 'ModalPlans',

  async fetch () { },

  data () {
    return {
      allPlans: [
        { currency: 'USD', interval: 'monthly', name: 'Professional', price: '59' },
        { currency: 'USD', interval: 'monthly', name: 'Plus', price: '29' },
        { currency: 'USD', interval: 'monthly', name: 'Starter', price: '19' },
        { currency: 'USD', interval: 'monthly', name: 'Free', price: '0' },
        { currency: 'USD', interval: 'yearly', name: 'Professional', price: '708' },
        { currency: 'USD', interval: 'yearly', name: 'Plus', price: '348' },
        { currency: 'USD', interval: 'yearly', name: 'Starter', price: '180' },
        { currency: 'USD', interval: 'yearly', name: 'Free', price: '0' },
        { currency: 'BRL', interval: 'monthly', name: 'Professional', price: '297' },
        { currency: 'BRL', interval: 'monthly', name: 'Plus', price: '197' },
        { currency: 'BRL', interval: 'monthly', name: 'Starter', price: '97' },
        { currency: 'BRL', interval: 'monthly', name: 'Free', price: '0' },
        { currency: 'BRL', interval: 'yearly', name: 'Professional', price: '2997' },
        { currency: 'BRL', interval: 'yearly', name: 'Plus', price: '1997' },
        { currency: 'BRL', interval: 'yearly', name: 'Starter', price: '997' },
        { currency: 'BRL', interval: 'yearly', name: 'Free', price: '0' }
      ],
      features: [
        { name: this.$t('components.account.modalPlans.plans.singleProfessional'), plans: ['Professional'] },
        { name: this.$t('components.account.modalPlans.plans.singlePlus'), plans: ['Plus'] },
        { name: this.$t('components.account.modalPlans.plans.singleStarter'), plans: ['Starter'] },
        { name: this.$t('components.account.modalPlans.plans.singleFree'), plans: ['Free'] },
        { name: this.$t('components.account.modalPlans.plans.unlimited'), plans: ['Professional', 'Plus', 'Starter', 'Free'] },
        { name: this.$t('components.account.modalPlans.plans.customEndingScreens'), plans: ['Professional', 'Plus', 'Starter'] },
        { name: this.$t('components.account.modalPlans.plans.automaticKeyword'), plans: ['Professional', 'Plus', 'Starter'] },
        { name: this.$t('components.account.modalPlans.plans.sentimentDetection'), plans: ['Professional', 'Plus', 'Starter'] },
        { name: this.$t('components.account.modalPlans.plans.emotionDetection'), plans: ['Professional', 'Plus', 'Starter'] }
      ],
      currency: 'USD',
      interval: 'monthly'
    }
  },

  computed: {
    ...mapState({
      userCurrency: state => state.auth.user?.currency || 'USD',
      currentPlan: state => state.account.subscription?.currentPlan,
      currentInterval: state => state.account.subscription?.currentInterval || 'monthly',
      planFeatures: state => state.account.planFeatures
    }),
    plans () {
      return this.allPlans.filter(plan => plan.currency === this.currency && plan.interval === this.interval)
    },
    currencySign () {
      return this.currency === 'USD' ? '$' : 'R$'
    },
    intervalNoun () {
      return this.interval === 'monthly' ? 'month' : 'year'
    },
    showEmptyState () {
      return !this.$fetchState.pending && !this.$fetchState.error
    },
    showErrorState () {
      return this.$fetchState.error
    }
  },

  watch: {
    currentInterval: {
      handler (newInterval) {
        this.interval = newInterval
      },
      immediate: true
    },
    userCurrency: {
      handler (newCurrency) {
        this.currency = newCurrency
      },
      immediate: true
    }
  },

  methods: {
    selectPlan (planName) {
      const checkoutUrl = `${process.env.API_URL}/billing/checkout?plan=${planName.toLowerCase()}&interval=${this.interval}&redirectUrl=${window.location.href}`
      window.location.href = checkoutUrl
    },
    getPlanFeatures (planName) {
      return this.features.filter(feature => feature.plans.includes(planName))
    }
  }
}
