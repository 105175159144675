
import formsMixin from '~/lib/mixins/forms.mixin'

export default {
  mixins: [formsMixin],

  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      model: {
        name: ''
      }
    }
  },

  mounted () {
    this.model.name = this.name
    this.$refs.name.$el.querySelector('input').focus()
  },

  methods: {
    async onSubmit () {
      try {
        this.$nuxt.$loading.start()
        this.clearErrors()

        await this.$store.dispatch('account/renameWorkspace', { workspaceId: this.id, name: this.model.name })
        await this.$emit('close')

        this.$nuxt.$loading.finish()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
