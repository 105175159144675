// import cookie from 'cookie'

export default ({ apiUrl }) => {
  function httpOptions (method, body) {
    const result = {
      method,
      // credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
        // 'X-CSRF-TOKEN': '',
        // 'X-XSRF-TOKEN': cookie.parse(document.cookie)['XSRF-TOKEN']
      }
    }

    if (body) { result.body = JSON.stringify(body) }

    return result
  }

  async function httpPut (url, body) {
    return await (await fetch(url, httpOptions('PUT', body))).json()
  }

  async function httpPost (url, body) {
    return await (await fetch(url, httpOptions('POST', body))).json()
  }

  async function httpDelete (url, body) {
    return await (await fetch(url, httpOptions('DELETE', body))).json()
  }

  async function httpGet (url) {
    return await (await fetch(url, httpOptions('GET'))).json()
  }

  async function getCsrfCookie () {

    // const sanctumUrl = apiUrl.replace('/api', '')

    // return await (await fetch(`${sanctumUrl}/auth/csrf-cookie`, httpOptions('GET')))
  }

  async function post (url, body) {
    await getCsrfCookie()
    return await httpPost(url, body)
  }

  /**
   * Finds all items of the given collection.
   *
   * @param {String} collection The collection to be added.
   * @param {Object} [entityId] (Optional) The id of the entity.
   * @returns {Object}
   * @memberof ServiceBase
   */
  async function find (collection) {
    return await httpGet(`${apiUrl}/${collection}`)
  }

  /**
   * Finds one entity of the given collection.
   *
   * @param {String} collection The collection to be added.
   * @param {Object} [entityId] (Optional) The id of the entity.
   * @returns {Object}
   * @memberof ServiceBase
   */
  async function findOne (collection, entityId) {
    return await httpGet(`${apiUrl}/${collection}/${entityId}`)
  }

  /**
   * Creates a new entity with timestamps on the collection.
   *
   * @param {String} collection The collection to be added.
   * @param {Object} entity The object to be added to the collection.
   * @returns {Object}
   * @memberof ServiceBase
   */
  async function create (collection, entity) {
    return await httpPost(`${apiUrl}/${collection}`, entity)
  }

  /**
   * Updates an entity and sets the updateAt field.
   *
   * @param {String} collection The collection where the object is located.
   * @param {Object} entityId The id of the entity.
   * @param {Object} entity The object to be updated to the collection.
   * @returns {String} Returns the entityId.
   * @memberof ServiceBase
   */
  async function update (collection, entityId, entity) {
    return await httpPut(`${apiUrl}/${collection}/${entityId}`, entity)
  }

  /**
   * Sets the deletedAt field to now.
   *
   * @param {String} collection The collection where the object is located.
   * @param {Object} entityId The id of the entity.
   * @returns {String} Returns the entityId.
   * @memberof ServiceBase
   */
  async function del (collection, entityId) {
    return await httpDelete(`${apiUrl}/${collection}/${entityId}`)
  }

  /**
   * Sets the deletedAt field to null.
   *
   * @param {String} collection The collection where the object is located.
   * @param {Object} entityId The id of the entity.
   * @returns {String} Returns the entityId.
   * @memberof ServiceBase
   */
  async function undoDeletion (collection, entityId) {
    return await httpPost(`${apiUrl}/${collection}/${entityId}`)
  }

  return {
    post,
    find,
    findOne,
    create,
    update,
    delete: del,
    undoDeletion,
    getCsrfCookie
  }
}
