import { render, staticRenderFns } from "./TheNavbar.vue?vue&type=template&id=494af46e&"
import script from "./TheNavbar.vue?vue&type=script&lang=js&"
export * from "./TheNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack-edge/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/workspace/components/layout/Logo.vue').default,TheWorkspacesDropdown: require('/workspace/components/layout/TheWorkspacesDropdown.vue').default,TheWorkspaceOptions: require('/workspace/components/layout/TheWorkspaceOptions.vue').default,TheUpgradePlanButton: require('/workspace/components/layout/TheUpgradePlanButton.vue').default,TheUserAvatarDropdown: require('/workspace/components/layout/TheUserAvatarDropdown.vue').default})
