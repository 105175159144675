import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'auth' })

  // async signIn (email, password) {
  //   await this.auth.loginWith('laravelSanctum', { data: { email, password } })
  // }

  async function signup (name, phone, email, password, timezoneName, language, tracking, initiateCheckout) {
    // await repository.getCsrfCookie()
    return await repository.post('auth/signup', { name, phone, email, password, timezoneName, language, tracking, initiateCheckout })
  }

  async function confirmCode (email, code) {
    // await repository.getCsrfCookie()
    return await repository.post('auth/confirm-code', { email, code })
  }

  async function sendCode (email) {
    // await repository.getCsrfCookie()
    return await repository.post('auth/send-code', { email })
  }

  async function sendPasswordResetEmail (email) {
    // await repository.getCsrfCookie()
    return await repository.post('auth/forgot-password', { email })
  }

  async function confirmPasswordReset (email, password, token) {
    // await repository.getCsrfCookie()
    return await repository.post('auth/reset-password', { email, password, token })
  }

  async function token (email, password) {
    return await repository.post('auth/token', { email, password })
  }

  async function getLoginLink (queryString) {
    return await repository.find(`auth/link${queryString}`)
  }

  return {
    signup,
    confirmCode,
    sendCode,
    sendPasswordResetEmail,
    confirmPasswordReset,
    token,
    getLoginLink
  }
}
